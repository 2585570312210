<script setup lang="ts">
// export default {
//   name: 'GlueAppNavigation',

//   components: {
//     MobileNavigation: () => import('./MobileNavigation.vue'),
//   },

//   computed: {
//     headerMenu() {
//       return window.deviseSettings.$page.site.data.headerMenu;
//     },
//     menu() {
//       return window.deviseSettings.$page.site.data.glueMainMenu;
//     },
//   },
// };

const menu = []
</script>

<template>
  <div class="overflow-hidden">
    <NavigationMobileNavigation :menu="menu" :show-book-now="false" />
  </div>
</template>
